/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ArrowUpward } from '@material-ui/icons'
import * as React from 'react'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

const StyledButton = styled.div`
  ${({ theme }) => css`
    .top-to-btm{
        position: relative;
      }
      .icon-position{
        position: fixed;
        bottom: 40px;
        right: 25px;
        z-index: 20;
      }
      .icon-style{
        background: ${theme.colours.primaryTint1};
        color: white;
        width: 5rem;
        height: 5rem;
        box-shadow: 0 3px 10px 0 rgba(122, 95, 63, 0.47);
        border-radius: 50%;
        cursor: pointer;
        animation: movebtn 3s ease-in-out infinite;
        transition: all .5s ease-in-out;
      }
      .icon-style:hover{
        animation: none;
      }
      @keyframes movebtn {
        0%{
          transform: translateY(0px);
        }
       
        50%{
          transform: translateY(5px);
        }
        
        100%{
          transform: translateY(0px);
        }
      }
    button {
        background: none;
        border: none;
        text-decoration: underline;
        cursor: pointer;
    }

  `}
`

const JumpToTopBtn = (): JSX.Element => {

    const [showTopBtn, setShowTopBtn] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true)
            } else {
                setShowTopBtn(false)
            }
        })
    }, [])

    
    const jumpToArticleTop = (): void => {
      window.scrollTo({ top:0, left:0, behavior: 'smooth' })
    }

  return (

      <StyledButton>
      <div className="top-to-btm">
        {showTopBtn && (
            <button type="button" onClick={jumpToArticleTop} className="icon-style icon-position">
                <ArrowUpward />
            </button>
        )}
      </div>
      </StyledButton>
  )
}

export default JumpToTopBtn
